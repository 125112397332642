import ApiService from "@/core/services/api.service";
import {SET_ACCOUNT_INFO} from "./profile.module";

export const GET_LEVELS = "getLevels";
export const SET_LEVELS = "setLevels";
export const CREATE_LEVEL = "createLevel";
export const UPDATE_LEVEL = "updateLevel";
export const DELETE_LEVEL = "deleteLevel";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  levels: [],
};

const actions = {
  [GET_LEVELS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("levels")
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          context.commit(SET_LEVELS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_LEVEL](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("levels/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_LEVEL](context, payloads) {
    return new Promise(resolve => {
      ApiService.post("levels/", payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_LEVEL](context, payloads) {
    return new Promise(resolve => {
      ApiService.put("levels/"+payloads.pk+'/', payloads)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const getters = {
  currentLevels(state) {
    return state.levels;
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_LEVELS](state, levels) {
    state.levels = levels;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
