import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
   mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/active-members",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/active-members",
          name: "Active Members",
          component: () => import("@/view/pages/Dashboard.vue")
        },
      ]
    },
     {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/td-auth",
          name: "td-auth",
          component: () => import("@/view/pages/Auth.vue")
        },
      ]
    },
      {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/invoices",
          name: "invoices",
          component: () => import("@/view/pages/Invoices.vue")
        },
      ]
    },

     {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/statements",
          name: "statements",
          component: () => import("@/view/pages/Statements.vue")
        },
      ]
    },

      {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/Users.vue")
        },
      ]
    },

    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
  ]
});
