import ApiService from "@/core/services/api.service";

export const GET_STATEMENTS = "getStatements";
export const CREATE_STATEMENT = "createStatement";
export const UPDATE_STATEMENT = "updateStatement";
export const DELETE_STATEMENT = "deleteStatement";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  symbols: [],
};

const actions = {
  [GET_STATEMENTS](context, payloads) {
    return new Promise(resolve => {
      ApiService.get("statements")
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_STATEMENT](context, pk) {
    return new Promise(resolve => {
      ApiService.delete("statements/"+pk+'/')
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [CREATE_STATEMENT](context, payloads) {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append("file", payloads.file);
      formData.append("name", payloads.name);
      formData.append("location", payloads.location);
      ApiService.post("statements/", formData)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

  [UPDATE_STATEMENT](context, payloads) {
    return new Promise(resolve => {
       const formData = new FormData();
      formData.append("file", payloads.file);
      formData.append("name", payloads.name);
      formData.append("pk", payloads.pk);
      formData.append("location", payloads.location);
      ApiService.put("statements/"+payloads.pk+'/', formData)
        .then(({ data }) => {
          context.commit(SET_ERROR, {});
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },

};

const getters = {

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
